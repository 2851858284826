import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { getCookie } from '@/libs/utils'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/master-data',
        name: 'Master Data',
        redirect: '/master-data/branch',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/master-data/branch',
            name: 'Cabang',
            redirect: '/master-data/branch/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/branch/list',
                name: 'List Cabang',
                component: () => import('@/views/master-data/branches/BranchList'),
              },
              {
                path: '/master-data/branch/add',
                name: 'Add Cabang',
                props: {
                  formType: 'add',
                },
                component: () => import('@/views/master-data/branches/BranchForm'),
              },
              {
                path: '/master-data/branch/edit/:id',
                name: 'Edit Cabang',
                props: {
                  formType: 'edit',
                },
                component: () => import('@/views/master-data/branches/BranchForm'),
              },
            ],
          },
          {
            path: '/master-data/salesman',
            name: 'Salesman',
            redirect: '/master-data/salesman/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/salesman/list',
                name: 'List Salesman',
                component: () => import('@/views/master-data/salesman/SalesmanList'),
              },
            ],
          },
          {
            path: '/master-data/users',
            name: 'Pengguna',
            redirect: '/master-data/users/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/users/list',
                name: 'List',
                component: () => import('@/views/master-data/users/UserLists'),
              },
              {
                path: '/master-data/users/add',
                name: 'Buat Baru',
                component: () => import('@/views/master-data/users/UserForm'),
              },
              {
                path: '/master-data/users/:userId/edit',
                name: 'Edit User',
                component: () => import('@/views/master-data/users/UserForm'),
              },
            ],
          },
          {
            path: '/master-data/roles',
            name: 'Role',
            redirect: '/master-data/roles/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/roles/list',
                name: 'Daftar Role',
                component: () => import('@/views/master-data/roles-permissions/RoleLists'),
              },
              {
                path: '/master-data/roles/add',
                name: 'Buat Role Baru',
                component: () => import('@/views/master-data/roles-permissions/RoleForm'),
              },
              {
                path: '/master-data/roles/:roleId/edit',
                name: 'Daftar Permission',
                component: () => import('@/views/master-data/roles-permissions/RoleForm'),
              },
            ],
          },
          {
            path: '/master-data/supplier',
            name: 'Supplier',
            redirect: '/master-data/supplier/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/supplier/list',
                name: 'List Supplier',
                component: () => import('@/views/master-data/suppliers/SupplierList'),
              },
              {
                path: '/master-data/supplier/add',
                name: 'Add Supplier',
                props: {
                  formType: 'add',
                },
                component: () => import('@/views/master-data/suppliers/SupplierForm'),
              },
              {
                path: '/master-data/supplier/edit/:id',
                name: 'Edit Supplier',
                props: {
                  formType: 'edit',
                },
                component: () => import('@/views/master-data/suppliers/SupplierForm'),
              },
              {
                path: '/master-data/supplier/contracts',
                name: 'List Kontrak',
                component: () => import('@/views/master-data/suppliers/SupplierContracts'),
              },
              {
                path: '/master-data/supplier/contracts/:supplierId',
                name: 'List Kontrak Supplier',
                component: () => import('@/views/master-data/suppliers/SupplierContracts'),
              },
            ],
          },
          {
            path: '/master-data/customer',
            name: 'Customer',
            redirect: '/master-data/customer/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/customer/list',
                name: 'List Customer',
                component: () => import('@/views/master-data/customers/CustomerList'),
              },
              {
                path: '/master-data/customer/add',
                name: 'Add Customer',
                props: {
                  formType: 'add',
                },
                component: () => import('@/views/master-data/customers/CustomerForm'),
              },
              {
                path: '/master-data/customer/edit/:id',
                name: 'Edit Customer',
                props: {
                  formType: 'edit',
                },
                component: () => import('@/views/master-data/customers/CustomerForm'),
              },
            ],
          },
          {
            path: '/master-data/product',
            name: 'Produk',
            redirect: '/master-data/product/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/product/list',
                name: 'List Product',
                component: () => import('@/views/master-data/products/ProductList'),
              },
              {
                path: '/master-data/product/add',
                name: 'Add Customer',
                props: {
                  formType: 'add',
                },
                component: () => import('@/views/master-data/products/ProductForm'),
              },
              {
                path: '/master-data/product/edit/:id',
                name: 'Edit Product',
                props: {
                  formType: 'edit',
                },
                component: () => import('@/views/master-data/products/ProductForm'),
              },
              {
                path: '/master-data/product/category',
                name: 'List Category',
                component: () => import('@/views/master-data/products/CategoryList'),
              },
              {
                path: '/master-data/product/unit',
                name: 'List Unit',
                component: () => import('@/views/master-data/products/UnitList'),
              },
            ],
          },
          {
            path: '/master-data/tax-types',
            name: 'Tipe Pajak',
            redirect: '/master-data/tax-types/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/tax-types/list',
                name: 'List Tipe Pajak',
                component: () => import('@/views/master-data/tax-types/TaxTypeList'),
              },
            ],
          },
          {
            path: '/master-data/account',
            name: 'Account',
            redirect: '/master-data/account/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/account/list',
                name: 'List Account',
                component: () => import('@/views/master-data/accounts/AccountList'),
              },
            ],
          },
          {
            path: '/master-data/transaction-cost',
            name: 'Transaction Cost',
            redirect: '/master-data/transaction-cost/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/transaction-cost/list',
                name: 'List Transaction Cost',
                component: () => {
                  return import('@/views/master-data/transaction-costs/TransactionCostList')
                },
              },
            ],
          },
          {
            path: '/master-data/grade',
            name: 'Grades',
            redirect: '/master-data/grade/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/grade/list',
                name: 'List Grade',
                component: () => {
                  return import('@/views/master-data/grades/GradeList')
                },
              },
            ],
          },
        ],
      },
      {
        path: '/purchase',
        name: 'Purchase',
        redirect: '/purchase/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/purchase/list',
            name: 'List Purchase',
            component: () => import('@/views/purchase/PurchaseList'),
          },
          {
            path: '/purchase/add',
            name: 'Buat baru',
            component: () => import('@/views/purchase/PurchaseForm'),
          },
          {
            path: '/purchase/:purchaseId/edit',
            name: 'Edit purchase',
            component: () => import('@/views/purchase/PurchaseForm'),
          },
          {
            path: '/purchase/:purchaseId/details',
            name: 'Detail purchase',
            component: () => import('@/views/purchase/PurchaseDetails'),
          },
          {
            path: '/purchase/returs',
            name: 'List retur',
            component: () => import('@/views/purchase/PurchaseReturnList'),
          },
          {
            path: '/purchase/returs/add',
            name: 'Tambah retur',
            component: () => import('@/views/purchase/PurchaseReturnForm'),
          },
          {
            path: '/purchase/returs/:returId/edit',
            name: 'Edit retur',
            component: () => import('@/views/purchase/PurchaseReturnForm'),
          },
          {
            path: '/purchase/payments',
            name: 'Daftar pembayaran pembelian',
            component: () => import('@/views/purchase/PurchasePaymentList'),
          },
          {
            path: '/purchase/payments/add',
            name: 'Buat data pembayaran pembelian',
            component: () => import('@/views/purchase/PurchasePaymentForm'),
          },
          {
            path: '/purchase/payments/:paymentId/edit',
            name: 'Edit data pembayaran pembelian',
            component: () => import('@/views/purchase/PurchasePaymentForm'),
          },
          {
            path: '/purchase/costs',
            name: 'Daftar biaya pembelian',
            component: () => import('@/views/purchase/PurchaseCostList'),
          },
          {
            path: '/purchase/costs/add',
            name: 'Buat biaya pembelian',
            component: () => import('@/views/purchase/PurchaseCostForm'),
          },
          {
            path: '/purchase/costs/:costId/edit',
            name: 'Edit biaya pembelian',
            component: () => import('@/views/purchase/PurchaseCostForm'),
          },
          {
            path: '/purchase/requests',
            name: 'Daftar permintaan pembelian',
            component: () => import('@/views/purchase/PurchaseRequestList'),
          },
          {
            path: '/purchase/requests/add',
            name: 'Buat permintaan pembelian',
            component: () => import('@/views/purchase/PurchaseRequestForm'),
          },
          {
            path: '/purchase/requests/:reqId/edit',
            name: 'Edit permintaan pembelian',
            component: () => import('@/views/purchase/PurchaseRequestForm'),
          },
        ],
      },
      {
        path: '/mutations',
        name: 'Mutasi Barang',
        redirect: '/mutations/purchases',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/mutations/purchases',
            name: 'Penerimaan Barang',
            component: () => import('@/views/purchase-mutations/PurchaseMutationList'),
          },
          {
            path: '/mutations/purchases/:purchaseId/add',
            name: 'Buat data penerimaan barang',
            component: () => import('@/views/purchase-mutations/PurchaseMutationForm'),
          },
          {
            path: '/mutations/purchases/:mutationId/edit',
            name: 'Edit data penerimaan barang',
            component: () => import('@/views/purchase-mutations/PurchaseMutationForm'),
          },
          {
            path: '/mutations/returns/:returnId/add',
            name: 'Buat data penerimaan barang retur',
            component: () => import('@/views/purchase-mutations/ReturnMutationForm'),
          },
          {
            path: '/mutations/returns/:mutationId/edit',
            name: 'Edit data penerimaan barang retur',
            component: () => import('@/views/purchase-mutations/ReturnMutationForm'),
          },
          {
            path: '/mutations/stock',
            name: 'Mutasi Penjualan',
            component: () => import('@/views/stock-mutations/StockMutationList'),
          },
          {
            path: '/mutations/stock/add',
            name: 'Buat data mutasi penjualan',
            component: () => import('@/views/stock-mutations/StockMutationForm'),
          },
          {
            path: '/mutations/stock/:mutationId/edit',
            name: 'Edit data mutasi penjualan',
            component: () => import('@/views/stock-mutations/StockMutationForm'),
          },
          {
            path: '/mutations/sales',
            name: 'Pengeluaran Barang',
            component: () => import('@/views/sales-mutations/SalesMutationList'),
          },
          {
            path: '/mutations/stock-card',
            name: 'Stock Card',
            component: () => import('@/views/logistic/StockCard'),
          },
        ],
      },
      {
        path: '/stocks',
        name: 'Stok',
        redirect: '/stocks/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/stocks',
            name: 'List stok barang',
            component: () => import('@/views/logistic/StockList'),
          },
          {
            path: '/stocks/opname',
            name: 'Opname stock barang',
            component: () => import('@/views/logistic/StockOpnameForm'),
          },
        ],
      },
      {
        path: '/sales',
        name: 'Sales',
        redirect: '/sales/order',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'order',
            name: 'List Sales Order',
            component: () => import('@/views/sales/SalesList'),
          },
          {
            path: 'costs',
            name: 'List Biaya SO',
            props: {
              sos: {},
            },
            component: () => import('@/views/sales/CostList'),
          },
          {
            path: 'invoice',
            name: 'List Invoice',
            props: {
              invoice: true,
            },
            component: () => import('@/views/sales/SalesList'),
          },
          {
            path: 'payment',
            name: 'List Pembayaran SO',
            props: {
              sos: {},
            },
            component: () => import('@/views/sales/PaymentList'),
          },
          {
            path: 'add',
            name: 'Tambah SO',
            component: () => import('@/views/sales/SalesForm'),
          },
          {
            path: 'edit/:id',
            name: 'Edit SO',
            component: () => import('@/views/sales/SalesForm'),
          },
          {
            path: 'detail/:id',
            name: 'Detail SO',
            props: {
              editSO: false,
            },
            component: () => import('@/views/sales/SalesForm'),
          },
          {
            path: 'retur',
            name: 'List Retur',
            component: () => import('@/views/sales/SalesReturnList'),
          },
          {
            path: 'retur/:id',
            name: 'Detail Sales Retur',
            component: () => import('@/views/sales/SalesReturnDetail'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login Old',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/pages/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

const isAuthenticated = () => {
  const accessToken = getCookie('accessToken')
  return !!accessToken
}

export default router
