<template>
  <div v-if="authUser.roles.some(role => role === 'Super Admin') || authUser.branch.length > 1">
    <div v-if="layout === 'inline'">
      <CRow>
        <CFormLabel for="selectBranch" :class="'col-' + colLabelValue + ' col-form-label'">{{ labelText }}</CFormLabel>
        <CCol :xs="colSelectValue">
          <CFormSelect
            id="selectBranch"
            feedbackInvalid="Pilih salah satu divisi"
            required
            :value="modelValue"
            @change="$emit('update:modelValue', $event.target.value)"
          >
            <option value="">Pilih Cabang</option>
            <option v-for="branch in branches" :key="branch.id" :value="branch.id">
              {{ branch.name }}
            </option>
          </CFormSelect>
        </CCol>
      </CRow>
    </div>
    <CCol v-else-if="layout === 'standard'" :xs="colContainerValue">
      <CFormSelect
        id="selectBranch"
        feedbackInvalid="Pilih salah satu divisi"
        required
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
      >
        <option value="">Pilih Cabang</option>
        <option v-for="branch in branches" :key="branch.id" :value="branch.id">
          {{ branch.name }}
        </option>
        <template #label>{{ labelText }}</template>
      </CFormSelect>
    </CCol>
    <CCol v-else :xs="colSelectValue">
      <CFormSelect
        id="selectBranch"
        feedbackInvalid="Pilih salah satu divisi"
        required
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
      >
        <option value="">Pilih Cabang</option>
        <option v-for="branch in branches" :key="branch.id" :value="branch.id">
          {{ branch.name }}
        </option>
      </CFormSelect>
    </CCol>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import branchService from '@/services/branch-service'

export default {
  props: {
    layout: {
      type: String,
      required: false,
      default: 'other',
    },
    colSelectValue: {
      type: String,
      required: false,
      default: "6",
    },
    colLabelValue: {
      type: String,
      required: false,
      default: "6",
    },
    labelText: {
      type: String,
      required: false,
      default: "Cabang",
    },
    colContainerValue: {
      type: String,
      required: false,
      default: "12",
    },
    modelValue: String,
  },
  computed: {
    ...mapState(['authUser']),
  },
  data() {
    return {
      branches: [],
    }
  },
  created() {
    branchService.getAll().then(resp => {
      // if not a super admin, only display branches that are assigned to the user
      if (! this.authUser.roles.some(role => role === 'Super Admin')) {
        this.branches = resp.data.data.filter(item => this.authUser.branch.some(div => div.id === item.id));
        console.log(this.authUser.roles.some(role => role === 'Super Admin') || this.authUser.branch.length > 1);
      }
      // get all branches if user is super admin
      else {
        this.branches = resp.data.data
      }
    })
  }
}

</script>