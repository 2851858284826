<template>
  <CSidebar
    color-scheme="light"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img :src="logo" :height="40" class="sidebar-brand-full" />
      <img :src="icon" :height="35" class="sidebar-brand-narrow" />
    </CSidebarBrand>
    <AppSidebarNav :user="user || {}" :permissions="permissions" />
    <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')" />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      user: computed(() => store.state.authUser),
      permissions: computed(() => store.state.permissions),
      logo: require('../assets/brand/LogoWms.svg'),
      icon: require('../assets/brand/IconWms.svg'),
    }
  },
}
</script>
