export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
    permission: '',
    role: '',
  },
  {
    component: 'CNavTitle',
    name: 'Transaksi & Invoice',
    permission: '',
    role: '',
  },
  {
    component: 'CNavGroup',
    name: 'Penjualan',
    to: '/sales',
    icon: 'cil-cash',
    permission: 'View Sales',
    role: 'Sales',
    items: [
      {
        component: 'CNavItem',
        name: 'Order',
        to: '/sales/order',
        permission: 'View Sales',
        role: 'Sales',
      },
      {
        component: 'CNavItem',
        name: 'Biaya Sales Order',
        to: '/sales/costs',
        permission: 'View Sales Cost',
        role: 'Sales',
      },
      {
        component: 'CNavItem',
        name: 'Sales Retur',
        to: '/sales/retur',
        permission: 'View Sales Retur',
        role: 'Sales',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Purchase',
    to: '/purchase',
    icon: 'cil-truck',
    permission: 'View Purchase',
    role: 'Purchase',
    items: [
      {
        component: 'CNavItem',
        name: 'List Pembelian',
        to: '/purchase/list',
        permission: 'View Purchase',
        role: 'Purchase',
      },
      {
        component: 'CNavItem',
        name: 'List Retur',
        to: '/purchase/returs',
        permission: 'View Purchase Retur',
        role: 'Purchase',
      },
      {
        component: 'CNavItem',
        name: 'List Biaya Pembelian',
        to: '/purchase/costs',
        permission: 'View Purchase Cost',
        role: 'BOP',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Logistik',
    to: '/mutations',
    icon: 'cil-house',
    permission: 'View Logistic',
    role: 'Logistic',
    items: [
      {
        component: 'CNavItem',
        name: 'Stok Barang',
        to: '/stocks',
        permission: 'View Logistic',
        role: 'Logistic',
      },
      {
        component: 'CNavItem',
        name: 'Penerimaan Barang',
        to: '/mutations/purchases',
        permission: 'Update Logistic',
        role: 'Logistic',
      },
      {
        component: 'CNavItem',
        name: 'Pengeluaran Barang',
        to: '/mutations/sales',
        permission: 'Update Logistic',
        role: 'Logistic',
      },
      {
        component: 'CNavItem',
        name: 'Mutasi Stok',
        to: '/mutations/stock',
        permission: 'Update Logistic',
        role: 'Logistic',
      },
      {
        component: 'CNavItem',
        name: 'Permintaan Pembelian',
        to: '/purchase/requests',
        permission: 'Create Logistic',
        role: 'Logistic',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Billing & Invoice',
    to: '/sales/invoice',
    icon: 'cil-cash',
    permission: 'View Sales Payments',
    role: 'Billing',
    items: [
      {
        component: 'CNavItem',
        name: 'Invoice / Tagihan',
        to: '/sales/invoice',
        permission: 'View Sales Payments',
        role: 'Billing',
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran SO',
        to: '/sales/payment',
        permission: 'View Sales Payments',
        role: 'Billing',
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran Purchase',
        to: '/purchase/payments',
        permission: 'View Purchase Payment',
        role: 'Billing',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Master Data',
    permission: 'View Master',
    role: 'Super Admin',
  },
  {
    component: 'CNavItem',
    name: 'Cabang',
    to: '/master-data/branch/list',
    icon: 'cil-lan',
    permission: 'View Master Branch',
    role: 'Super Admin',
  },
  {
    component: 'CNavGroup',
    name: 'Users & Roles',
    to: '/master-data/users',
    icon: 'cil-group',
    permission: 'View Master User',
    role: 'Super Admin',
    items: [
      {
        component: 'CNavItem',
        name: 'Pengguna',
        to: '/master-data/users/list',
        permission: 'View Master User',
        role: 'Super Admin',
      },
      {
        component: 'CNavItem',
        name: 'Roles',
        to: '/master-data/roles/list',
        permission: 'View Master Roles',
        role: 'Super Admin',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Salesman',
    to: '/master-data/salesman/list',
    icon: 'cil-bus-alt',
    permission: 'View Master Salesman',
    role: 'Super Admin',
  },
  {
    component: 'CNavGroup',
    name: 'Supplier & Kontrak',
    to: '/master-data/supplier',
    icon: 'cil-notes',
    permission: 'View Master User',
    role: 'Super Admin',
    items: [
      {
        component: 'CNavItem',
        name: 'Supplier',
        to: '/master-data/supplier/list',
        permission: 'View Master Supplier',
        role: 'Super Admin',
      },
      {
        component: 'CNavItem',
        name: 'Kontrak Supplier',
        to: '/master-data/supplier/contracts',
        permission: 'View Master Supplier Contract',
        role: 'Super Admin',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Customer',
    to: '/master-data/customer/list',
    icon: 'cil-cart',
    permission: 'View Master Customer',
    role: 'Super Admin',
  },
  {
    component: 'CNavGroup',
    name: 'Data Produk',
    to: '/master-data/product',
    icon: 'cil-star',
    permission: 'View Master Product',
    role: 'Super Admin',
    items: [
      {
        component: 'CNavItem',
        name: 'Product',
        to: '/master-data/product/list',
        permission: 'View Master Product',
        role: 'Super Admin',
      },
      {
        component: 'CNavItem',
        name: 'Product Category',
        to: '/master-data/product/category',
        permission: 'View Master Product Category',
        role: 'Super Admin',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Jenis Pajak',
    to: '/master-data/tax-types/list',
    icon: 'cil-star',
    permission: 'View Master Tax Type',
    role: 'Super Admin',
  },
  {
    component: 'CNavItem',
    name: 'Daftar Account',
    to: '/master-data/account/list',
    icon: 'cil-bank',
    permission: 'View Master Account',
    role: 'Super Admin',
  },
  {
    component: 'CNavItem',
    name: 'Transaction Cost',
    to: '/master-data/transaction-cost/list',
    icon: 'cil-calculator',
    permission: 'View Master Cost',
    role: 'Super Admin',
  },
  {
    component: 'CNavItem',
    name: 'Grade',
    to: '/master-data/grade/list',
    icon: 'cil-calculator',
    permission: 'View Grade',
    role: 'Super Admin',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
